//jsxhook

import { StandardXAxisPadding } from "@sixty-six-north/ui-system"
import Link from "components/Link"
import { usePreferredLanguage, useStoreContext } from "i18n/StoreHooks"
import React from "react"
import { useTranslation } from "react-i18next"
import { Box, Flex } from "theme-ui"
import { LinkBuilder } from "../../components/Links"
import { DomainCategory } from "../../product/models/DomainCategory"
import {
  DomainProduct,
  DomainProducts
} from "../../product/models/DomainProduct"
import { ProductProxy } from "../../product/ProductProxy"
import { PrismicSlice } from "../PrismicModels"
import { useRecommendations } from "../PrismicProductsContext"

const getRootCategories = (product: DomainProduct): DomainCategory[] => {
  return product.categories.filter(it => it.ancestors.length === 0)
}

const getCategoryUrlForProduct = ({ product, category, language, country }) => {
  const linkBuilder = new LinkBuilder(country)

  if (!product) return null

  const domainProduct = DomainProducts.fromProductProjection(product)
  const proxy = new ProductProxy(domainProduct)
  const rootCategories = getRootCategories(domainProduct)
  const preferredCategory = domainProduct.categories.filter(cat => {
    const parentURL = cat.key?.split("-")
    if (parentURL && parentURL[0] === category) {
      return category
    }
    return false
  })
  const currentCategorySlug =
    preferredCategory[0]?.slug[language] ||
    rootCategories[0]?.slug[language] ||
    preferredCategory[0]?.slug[language] ||
    ""

  return linkBuilder.productProjectionLink(
    currentCategorySlug,
    undefined,
    proxy.uri,
    undefined,
    undefined,
    null
  )
}

const linkStyle = {
  display: ["table", null, null, "inline-block"],
  flex: ["unset", null, null, "none"],
  marginLeft: [0, 0, 0, 16],
  textDecoration: "none",
  color: "blue.0",
  "&:first-child": {
    marginLeft: 0
  }
}

export const ProductListCaptionSlice: React.FC<{ data: PrismicSlice }> = ({
  data
}) => {
  const { currency, formatCurrency, country } = useStoreContext()
  const recommendations = useRecommendations()
  const { t } = useTranslation()
  const language = usePreferredLanguage()

  const getProductTitleAndPrice = product => {
    const topLevelDomainProduct = DomainProducts.fromProductProjection(product)
    const proxy = new ProductProxy(topLevelDomainProduct)
    const name = proxy.name(language).getOrElse("")
    const price = proxy.attributes
      .price(currency)
      .map(p => formatCurrency(p))
      .getOrElse("")
    const discountedPrice = proxy.attributes
      .discountedPrice(currency)
      .map(p => formatCurrency(p))
      .getOrElse(undefined)

    return `${name} ${proxy.listingDescription(language).getOrElse("")}, ${t(
      "layout:from"
    ).toLowerCase()} ${discountedPrice || price}`
  }

  const getProductUrlsForTopCategories = item => {
    //men product => item.product_id in the Prismic slice
    //women product => item.product_id_2 in the Prismic slice
    const menProductCode = item.product_id?.productCode
    const womenProductCode = item.product_id_2?.productCode
    const menProduct = recommendations.productById(menProductCode)
    const womenProduct = recommendations.productById(womenProductCode)

    const menUrl = getCategoryUrlForProduct({
      product: menProduct,
      category: "men",
      language,
      country
    })
    const womenUrl = getCategoryUrlForProduct({
      product: womenProduct,
      category: "women",
      language,
      country
    })

    return [menUrl, womenUrl]
  }

  if (!data.items.length) return null

  return (
    <Box
      sx={{
        width: "100%",
        mb: [32, null, null, 64],
        px: StandardXAxisPadding
      }}
    >
      {data.items.map((item, idx) => {
        const code =
          item.product_id?.productCode || item.product_id_2?.productCode
        const mainProductData = recommendations.productById(code)

        if (!mainProductData) return null

        const title = getProductTitleAndPrice(mainProductData)
        const [menUrl, womenUrl] = getProductUrlsForTopCategories(item)

        return (
          <Flex
            key={`product-list-caption-${code}-${idx}`}
            sx={{
              alignItems: ["left", null, null, "center"],
              justifyContent: "space-between",
              flexDirection: ["column", null, null, "row"],
              flexWrap: ["wrap", null, null, "nowrap"],
              paddingTop: [8, null, null, 16],
              paddingBottom: [8, null, null, 16],
              borderBottom: "1px solid",
              borderColor: "grey.3"
            }}
          >
            <Box sx={{ flex: ["1 0 100%", null, null, "1 0 70%"] }}>
              {title}
            </Box>
            <Box
              sx={{
                flex: ["1 0 100%", null, null, "1 0 30%"],
                textAlign: ["left", null, null, "right"]
              }}
            >
              {menUrl && (
                <Link sx={linkStyle} {...menUrl}>
                  {t(`footer:menLink`)}
                </Link>
              )}
              {womenUrl && (
                <Link sx={linkStyle} {...womenUrl}>
                  {t(`footer:womenLink`)}
                </Link>
              )}
            </Box>
          </Flex>
        )
      })}
    </Box>
  )
}
