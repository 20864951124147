//jsxhook

import { useContext, useEffect, useState } from "react"
import { ProductFacets } from "../category/ProductFacetFactory"
import { CoreProductInformation } from "../product/models/DetailedProductInformation"
import { DomainProduct } from "../product/models/DomainProduct"
import { bySizeKeyOrder } from "../product/ProductHooks"
import { FilterValue } from "./FilterComponent"
import { FilterContext } from "./FilterProvider"
import { FilterService } from "./FilterService"

export interface SubColors {
  [colorTerm: string]: string[]
}

export const useColors = (
  facets: ProductFacets
): { colors: FilterValue[]; subColors: SubColors } => {
  const filter = useFilterService()
  const registry: Record<string, FilterValue> = {}

  const { colors, subColors } = facets
  colors.forEach(c => {
    registry[c.filterValue] = {
      ...c,
      isActive: filter.colorTerm.isActive(c.filterValue)
    }
  })

  return { colors: Object.keys(registry).map(key => registry[key]), subColors }
}

export const useSizes = (facets: ProductFacets): FilterValue[] => {
  const filter = useFilterService()

  return facets.sizes
    .map(s => ({
      ...s,
      isActive: filter.size.isActive(s.filterValue)
    }))
    .sort((left, right) =>
      bySizeKeyOrder({ size: left.filterValue }, { size: right.filterValue })
    )
}

export const useStyles = (facets: ProductFacets): FilterValue[] => {
  const filter = useFilterService()

  return facets.styles.map(s => ({
    ...s,
    isActive: filter.style.isActive(s.filterValue)
  }))
}

export const useFunctionalities = (facets: ProductFacets): FilterValue[] => {
  const filter = useFilterService()

  return facets.functionalities.map(s => ({
    ...s,
    isActive: filter.functionality.isActive(s.filterValue)
  }))
}

export const useFilterService = (): FilterService => {
  return useContext(FilterContext)
}

export interface FilteredProductsService {
  filteredProducts: CoreProductInformation[]
  toggleColor: (color: string) => void
  clearColor: () => void
  toggleStyle: (style: string) => void
  clearStyle: () => void
  toggleSize: (size: string) => void
  clearSize: () => void
  toggleFunctionality: (functionality: string) => void
  clearFunctionality: () => void
  clearAll: () => void
}

export const useFilteredProducts = (
  products: CoreProductInformation[]
): FilteredProductsService => {
  const filterService = useFilterService()
  const [filteredProducts, setFilteredProducts] = useState(
    filterService.filter(products)
  )

  useEffect(() => {
    filterService.clear()
    setFilteredProducts(products)
  }, [products])

  const withUpdates = (service: FilterService) => {
    setFilteredProducts(service.filter(products))
  }

  return {
    filteredProducts,
    clearColor: () => {
      withUpdates(filterService.colorTerm.clear().filterService)
    },
    toggleColor: (color: string) => {
      withUpdates(filterService.colorTerm.toggle(color).filterService)
    },
    clearStyle: () => {
      withUpdates(filterService.style.clear().filterService)
    },
    toggleStyle: (style: string) => {
      withUpdates(filterService.style.toggle(style).filterService)
    },
    clearSize: () => {
      withUpdates(filterService.size.clear().filterService)
    },
    toggleSize: (size: string) => {
      withUpdates(filterService.size.toggle(size).filterService)
    },
    toggleFunctionality: (functionality: string) => {
      withUpdates(
        filterService.functionality.toggle(functionality).filterService
      )
    },
    clearFunctionality: () => {
      withUpdates(filterService.functionality.clear().filterService)
    },
    clearAll: () => {
      filterService.clear()
      setFilteredProducts(products)
    }
  }
}
