//jsxhook

import {
  ArtDirectedImage,
  CollapsibleNew,
  Column,
  EmailIcon,
  FontWeight,
  ImageVariants,
  Label2,
  LinkVariants,
  OutlineLightButton,
  ParagraphSizeEnum,
  PrimaryButton,
  ProductPrice,
  Row,
  simpleFadeTransitionProperties,
  StandardXAxisPadding,
  StandardXAxisSpace,
  TechnicalParagraph,
  TextVariants,
  ZoomGalleryImage,
  ifBrowser
} from "@sixty-six-north/ui-system"
import { pdpDataUrl } from "Config"
import {
  m,
  useAnimation,
  useAnimationControls,
  useReducedMotion
} from "framer-motion"
import { useStoreContext } from "i18n/StoreHooks"
import { PreviewData } from "next"
import dynamic from "next/dynamic"
import Image from "next/image"
import { useRouter } from "next/router"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useInView } from "react-intersection-observer"
import { Box, Flex, Image as ThemeImage, Link } from "theme-ui"
import { ExpressCheckout } from "../../checkout/ExpressCheckout"
import { useContactLink } from "../../components/Links"
import { isIcelandicStore } from "../../FeatureFlags"
import { PrismicData, SimplePrismicDocument } from "../../prismic/PrismicModels"
import { useTrueFit } from "../../truefit/TrueFitHooks"
import logger from "../../utils/logger"
import { useIsMobile } from "../../utils/uiHelpers"
import { StockLevel } from "../models/DetailsPageVariant"
import { SKU } from "../VariantProxyI"
import { ColorSelectorNew, ColorSelectorProps } from "./ColorSelectorNew"
import {
  extractInTheFieldImagesFromPrismicData,
  InTheField
} from "./InTheField"
import { NameAndSku } from "./NameAndSku"
import { ProductArticles } from "./ProductArticles"
import { ProductRecommendations } from "./Recommendations"
import { SizeProps, SizeSelectorNew } from "./SizeSelector"
import { TrueFitWidget } from "./TrueFitWidget"

const ZoomGalleryModal = dynamic(
  // @ts-ignore
  import("./ZoomGalleryModal").then(it => it.ZoomGalleryModal)
)
const TEXT_TOP_OFFSET_POSITION = 88
const TEXT_ANIMATION_DURATION_SECONDS = 0.5
const IMAGE_COUNT_STICKY_HEIGHT = "2.5rem"
const IMAGE_MIN_HEIGHT = [null, null, null, null, 680]
const IMAGE_DIMENSIONS_STYLE = [
  "100% !important",
  null,
  null,
  null,
  "calc(100vh - 5.5rem) !important"
]

const Description: React.FC<{ text: string; variant?: ParagraphSizeEnum }> = ({
  text,
  variant = ParagraphSizeEnum.small,
  ...props
}) => {
  return (
    <Box {...props} sx={{ mt: 4 }}>
      <TechnicalParagraph variant={variant} sx={{ mt: 4 }}>
        {text}
      </TechnicalParagraph>
    </Box>
  )
}

export interface ProductContent {
  label: string
  image?: string
  elements: React.ReactNode
}

export interface GalleryImage {
  title: string
  url: string
}

export interface SingleProductProps {
  id: string
  name: string
  key?: string
  sku: SKU
  description: string
  phoneticSpelling?: string
  price: string
  colorCode?: string
  category: string
  discountedPrice?: string
  stockLevel: StockLevel
  galleryImages: GalleryImage[]
  color: ColorSelectorProps
  size: SizeProps
}

export interface AddToBagEventHandler {
  onClick: (
    ev: React.MouseEvent<HTMLButtonElement>,
    options?: { notify: boolean }
  ) => Promise<unknown>
  error: boolean
}

export interface ProductProps {
  product: SingleProductProps
  productPrismicData: SimplePrismicDocument | null
  suitableFor?: string[]
  previewData?: PreviewData
  content: ProductContent[]
  recommendationCollection?: any
  sizeGuide?: React.ReactNode
  addToBag?: AddToBagEventHandler
  notifyBackInStockClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void
  storeStockButton?: React.ReactNode
  freeShippingThreshold?: string
  imageZoomTracking?: () => void
  colorTracking?: () => void
  nameSpellingTracking?: (mode?: string) => void
  showNotifyBackInStock?: boolean
}

const SizeGuideComponent: React.FC<{ node?: React.ReactNode }> = ({ node }) => {
  const { t } = useTranslation("product")

  if (node) {
    return <React.Fragment>{node}</React.Fragment>
  } else {
    return (
      <Link href="#" variant={LinkVariants.standardNoUnderline}>
        {t("sizeGuide", "sizeGuide")}
      </Link>
    )
  }
}

interface ProductVariables {
  currentTextOffset: number
  zoom: {
    index: number | null
    prismicData: PrismicData | null
    images: ZoomGalleryImage[]
  }
}

const ProductImage = ({
  image,
  index,
  slideTotal,
  onVisibilityChange,
  onImageClick
}) => {
  const controls = useAnimation()
  const isMobile = useIsMobile()
  const reducedMotion = useReducedMotion()

  const { ref, inView, entry } = useInView({
    threshold: 0.01
  })

  const {
    ref: bottomRef,
    inView: bottomInView,
    entry: bottomEntry
  } = useInView({
    threshold: 0.01
  })

  const imageVariants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: reducedMotion ? 0 : 0.8 }
    },
    hidden: { opacity: 0, y: isMobile ? 0 : 40 }
  }

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
    return () => controls.stop()
  }, [controls, inView])

  useEffect(() => {
    // page load intersection
    if (entry?.isIntersecting) {
      controls.start("visible")
    }
  }, [entry])

  useEffect(() => {
    const currentY = bottomEntry?.boundingClientRect?.y || 0
    const isIntersecting = bottomEntry?.isIntersecting || false
    const initial =
      currentY < 0 || (currentY > 0 && isIntersecting) ? true : null
    onVisibilityChange({ inView: bottomInView, initial, index })
  }, [bottomInView])

  return (
    <m.div
      ref={ref}
      key={`gallery-image-${index}`}
      animate={controls}
      exit="visible"
      initial={index === 0 ? "visible" : "hidden"}
      transition={{ ...simpleFadeTransitionProperties }}
      variants={imageVariants}
      aria-label={`Image ${index + 1} of ${slideTotal}`}
      sx={{
        width: "100%",
        paddingBottom: IMAGE_DIMENSIONS_STYLE,
        minHeight: IMAGE_MIN_HEIGHT,
        flexShrink: 0,
        scrollSnapAlign: "start",
        display: ["block", null, null, "flex"],
        justifyContent: "center",
        variant: `images.${ImageVariants.ratio_1x1}`
      }}
    >
      <ArtDirectedImage
        sx={{
          display: "flex",
          justifyContent: "center",
          "& img, & > span > span, & > span": {
            height: IMAGE_DIMENSIONS_STYLE,
            minHeight: IMAGE_MIN_HEIGHT,
            width: "100%",
            variant: `text.${TextVariants.label3}`
          },
          "& > span:after": {
            display: ["block", null, null, "none"],
            content: `'${index + 1}/${slideTotal}'`,
            position: "absolute",
            fontWeight: FontWeight.textBold,
            bottom: 24,
            right: 24
          }
        }}
        imageNode={
          <Image
            sx={{
              cursor: "pointer"
            }}
            onKeyPress={k => {
              if (k.key === "Enter") {
                onImageClick()
              }
            }}
            onClick={() => onImageClick(index)}
            src={image.url}
            alt={image.title}
            width={1050}
            height={1050}
            priority={index === 0}
          />
        }
        imageAlt={image.title}
      />
      <span
        sx={{ position: "absolute", bottom: 0, height: 1, width: 1 }}
        ref={bottomRef}
      />
    </m.div>
  )
}

const stockLevelMessages: Record<StockLevel, string | undefined> = {
  "out-of-stock": "currentlyOutOfStock",
  "one-left": "oneLeftInStock",
  "two-left": "twoLeftInStock",
  "low-stock": "lowStock",
  "in-stock": undefined
}

export const ProductCore: React.FC<ProductProps> = ({
  product,
  content,
  recommendationCollection,
  sizeGuide,
  productPrismicData,
  suitableFor,
  previewData,
  addToBag,
  storeStockButton,
  notifyBackInStockClick,
  imageZoomTracking,
  colorTracking,
  nameSpellingTracking,
  showNotifyBackInStock = true
}) => {
  const { t } = useTranslation(["product", "category"])
  const textControls = useAnimationControls()
  const textContainer = useRef<HTMLDivElement>(null)
  const productImagesContainer = useRef<HTMLDivElement>(null)
  const [textOffset, setTextOffset] = useState(0)
  const [currentVisibleImage, setCurrentVisibleImage] = useState(1)
  const store = useStoreContext()
  const router = useRouter()
  const contactLink = useContactLink()
  const isMobile = useIsMobile()
  const hasMultipleSizes = product.size.sizes.length > 1
  const [showZoomGallery, setShowZoomGallery] = useState<boolean>(false)
  const trueFit = useTrueFit()

  const [prismicPronunciationData, setPrismicPronunciationData] =
    useState<PrismicData | null>(null)
  const productVars = useRef<ProductVariables>({
    currentTextOffset: 0,
    zoom: {
      index: null,
      prismicData: null,
      images: product.galleryImages || []
    }
  })

  let {
    current: { currentTextOffset }
  } = productVars
  const {
    current: { zoom: zoomVars }
  } = productVars

  const slideTotal = product.galleryImages.length

  const isInStock = product.stockLevel !== "out-of-stock"
  const handleDisplayZoomGallery = (startIndex: number) => {
    const productImages = product.galleryImages || []
    zoomVars.index = startIndex || 0
    zoomVars.images = productImages.concat(
      extractInTheFieldImagesFromPrismicData(zoomVars.prismicData)
    )
    if (imageZoomTracking) imageZoomTracking()
    setShowZoomGallery(true)
  }

  const updateCurrentVisibleImage = ({ inView, initial, index }) => {
    const newIndex = index + 1
    const smallScreen = window.innerWidth < 1440
    setCurrentVisibleImage(current => {
      if (inView) {
        if (smallScreen) {
          if (current - newIndex > 2) return newIndex
          else if (current - newIndex > 1) return current
        }
        return newIndex + 1 > slideTotal ? slideTotal : newIndex + 1
      } else {
        if (smallScreen && current - newIndex === 1 && !initial) return newIndex
        if (newIndex === 1 && !initial) return 1
      }
      return current
    })
  }

  const calculateTextOffset = () => {
    let newOffset =
      window.innerHeight - (textContainer?.current?.clientHeight || 0)
    if (newOffset > 0) {
      if (newOffset < TEXT_TOP_OFFSET_POSITION) newOffset = 0
      else newOffset = TEXT_TOP_OFFSET_POSITION
    }

    return newOffset
  }
  const refreshTextOffset = () => {
    const newOffset = calculateTextOffset()
    currentTextOffset = newOffset
    textControls.start({
      top: newOffset,
      transition: { duration: TEXT_ANIMATION_DURATION_SECONDS }
    })
  }

  let lastScrollTop = 0

  const refreshTextOffsetScroll = () => {
    const stickToTopOffset = TEXT_TOP_OFFSET_POSITION
    const st = window.pageYOffset || document.documentElement.scrollTop
    if (st > lastScrollTop) {
      // scrolls to bottom
      if (currentTextOffset === stickToTopOffset) refreshTextOffset()
    } else {
      // scrolls to top
      if (currentTextOffset !== stickToTopOffset) {
        currentTextOffset = stickToTopOffset
        textControls.start({
          top: stickToTopOffset,
          transition: { duration: TEXT_ANIMATION_DURATION_SECONDS }
        })
      }
    }
    lastScrollTop = st <= 0 ? 0 : st // For Mobile or negative scrolling
  }

  useEffect(() => {
    setTextOffset(calculateTextOffset())
    window.addEventListener("resize", refreshTextOffset)
    window.addEventListener("scroll", refreshTextOffsetScroll)
    return () => {
      window.removeEventListener("resize", refreshTextOffset)
      window.removeEventListener("scroll", refreshTextOffsetScroll)
    }
  }, [])

  useEffect(() => {
    trueFit.calculate()

    async function fetchPronunciationData() {
      try {
        const name = product.name.trim()
        const response = await fetch(pdpDataUrl(`${name}/pronunciation`))
        const { data } = (await response.json()) || {}
        setPrismicPronunciationData(data)
      } catch (e) {
        logger.warn(e)
      }
    }
    if (!isIcelandicStore(store)) fetchPronunciationData()
  }, [product.name])

  const stockLevelMessageTranslationKey = stockLevelMessages[product.stockLevel]
  return (
    <>
      <Flex sx={{ flexDirection: "column" }}>
        <Box
          sx={{
            position: "relative",
            mt: [0, null, null, `-${IMAGE_COUNT_STICKY_HEIGHT}`]
          }}
        >
          <Label2
            sx={{
              display: ["none", null, null, "inherit"],
              position: "sticky",
              top: `calc(100vh - 2.5rem)`,
              fontWeight: FontWeight.textBold,
              zIndex: 1,
              width: 40,
              height: IMAGE_COUNT_STICKY_HEIGHT,
              left: "1.5rem"
            }}
          >
            {currentVisibleImage}/{slideTotal}
          </Label2>
          <Flex
            sx={{
              backgroundColor: ["white.0", null, null, "grey.5"],
              width: "100%",
              flexWrap: ["wrap", null, null, "nowrap"]
            }}
          >
            <Flex
              ref={productImagesContainer}
              as="section"
              sx={{
                position: ["relative", null, null, "sticky"],
                flex: ["1 1 100%", null, null, "1 1 50%"],
                top: [0, null, null, "5.5rem"],
                overflowX: ["scroll", null, null, "hidden"],
                overflowY: "hidden",
                scrollSnapType: "x mandatory",
                flexDirection: ["row", null, null, "column"],
                height: ["100%", null, null, "calc(100% - 5.5rem)"]
              }}
            >
              <button
                sx={{
                  variant: "buttons.secondary",
                  position: "absolute",
                  zIndex: 1,
                  right: 48,
                  top: 16,
                  opacity: 0,
                  "&:focus": {
                    opacity: 1
                  }
                }}
                onClick={() => handleDisplayZoomGallery(0)}
              >
                View Image Gallery
              </button>
              {product.galleryImages.map((image, idx) => (
                <ProductImage
                  key={image.url}
                  image={image}
                  index={idx}
                  slideTotal={slideTotal}
                  onImageClick={index => handleDisplayZoomGallery(index)}
                  onVisibilityChange={updateCurrentVisibleImage}
                />
              ))}
            </Flex>
            <m.div
              animate={textControls}
              sx={{
                display: "flex",
                position: "sticky",
                top: [0, null, null, textOffset],
                flex: ["1 1 100%", null, null, "1 1 50%"],
                height: "100%",
                pl: [24, 24, 48, 48, 0],
                pr: [24, 24, 48, 48, 0, StandardXAxisSpace],
                flexDirection: "column"
              }}
            >
              <Flex
                ref={textContainer}
                sx={{
                  flexDirection: "column",
                  alignContent: "center",
                  pt: [16, null, null, 72],
                  width: "100%",
                  alignSelf: "center",
                  maxWidth: [
                    null,
                    null,
                    null,
                    null,
                    "calc(100vw * .35)",
                    "72ch"
                  ],
                  variant: "text.label2"
                }}
              >
                <Box
                  sx={{
                    display: ["block", null, null, "block"],
                    py: 16
                  }}
                >
                  <NameAndSku
                    key={product.name}
                    nameSpellingTracking={mode =>
                      nameSpellingTracking && nameSpellingTracking(mode)
                    }
                    phoneticSpelling={product.phoneticSpelling}
                    pronunciationData={prismicPronunciationData}
                    name={product.name}
                    sku={product.sku}
                  />
                  <Description text={product.description} />
                  <ProductPrice
                    price={product.price}
                    discountPrice={product.discountedPrice}
                    sx={{ mt: 16 }}
                  />
                </Box>

                <Box sx={{ py: 16 }}>
                  <ColorSelectorNew
                    {...product.color}
                    onColorSelected={() => {
                      if (!isMobile) {
                        const elem = productImagesContainer.current
                        const rect = elem?.getBoundingClientRect()
                        const posY =
                          window.pageYOffset ||
                          document.documentElement.scrollTop
                        if (posY < 150) return
                        window.scrollTo({
                          top: rect ? rect.top + posY - 99 : 0
                        })
                      } else {
                        productImagesContainer.current?.scrollTo({
                          left: 0
                        })
                      }

                      colorTracking && colorTracking()
                    }}
                  />
                </Box>
                <Box sx={{ py: 16 }}>
                  <Flex>
                    <Label2
                      id="size-label"
                      sx={{ fontWeight: FontWeight.textBold, mr: 4 }}
                    >
                      {t("category:size", "Size")}{" "}
                    </Label2>
                    <Label2>{product.size.selected.label}</Label2>
                    {hasMultipleSizes && (
                      <Label2 sx={{ ml: "auto" }}>
                        <SizeGuideComponent node={sizeGuide} />
                      </Label2>
                    )}
                  </Flex>

                  {hasMultipleSizes && (
                    <Box sx={{ mt: 16 }}>
                      <SizeSelectorNew
                        labelledBy="size-label"
                        disabledOutofStockSizes={false}
                        {...product.size}
                      />
                    </Box>
                  )}
                </Box>

                <>
                  {stockLevelMessageTranslationKey && (
                    <Row sx={{ flex: 1, mb: 16 }}>
                      <Label2 sx={{ color: "muted" }}>
                        {t(
                          stockLevelMessageTranslationKey,
                          "This product has low stock."
                        )}
                      </Label2>
                    </Row>
                  )}
                  {trueFit.available && <TrueFitWidget product={product} />}
                  {!isInStock && showNotifyBackInStock ? (
                    <PrimaryButton
                      fill
                      onClick={e =>
                        notifyBackInStockClick && notifyBackInStockClick(e)
                      }
                    >
                      <EmailIcon
                        sx={{ position: "absolute", left: 12, mr: 4 }}
                      />
                      {t(
                        "notifyBackInStockButton",
                        "Notify me when back in stock"
                      )}
                    </PrimaryButton>
                  ) : null}
                  {isInStock && (
                    <Column>
                      <PrimaryButton
                        fill
                        onClick={ev => addToBag && addToBag.onClick(ev)}
                      >
                        {t("addToBag", "Add to bag")}
                      </PrimaryButton>
                      {/*<ExpressCheckout sku={product.sku} />*/}
                    </Column>
                  )}
                  {addToBag && addToBag.error && (
                    <Box sx={{ mt: 12, color: "red.0" }}>
                      <Label2>
                        {t(
                          "addToBagError",
                          "We were unable to add the product to your bag. Please try again in a minute."
                        )}
                      </Label2>
                    </Box>
                  )}
                  <Row
                    sx={{
                      mt: addToBag && addToBag.error ? 12 : 24,
                      button: {
                        fontWeight: FontWeight.textRegular,
                        width: "100%"
                      }
                    }}
                  >
                    {storeStockButton && (
                      <Box sx={{ mr: 8, width: ["50%", null, null, "auto"] }}>
                        {storeStockButton}
                      </Box>
                    )}

                    <Box sx={{ width: ["50%", null, null, "auto"] }}>
                      <OutlineLightButton
                        onClick={() =>
                          router.push(contactLink.href, contactLink.as)
                        }
                      >
                        <EmailIcon sx={{ mr: 4 }} />
                        {t("customerService", "Customer Service")}
                      </OutlineLightButton>
                    </Box>
                  </Row>
                  <Row>
                    <Box
                      sx={{
                        width: "100%",
                        mt: [12, null, null, 40],
                        mb: [0, null, null, 40]
                      }}
                    >
                      {content.map((content, idx) => {
                        return (
                          <CollapsibleNew
                            label={content.label}
                            as="h2"
                            onVisibilityChange={refreshTextOffset}
                            key={`content-${idx}`}
                            initShow={idx === 0}
                          >
                            <Row
                              sx={{
                                flex: 1,
                                flexWrap: "wrap",
                                minWidth: "24ch"
                              }}
                            >
                              {content.image && (
                                <Box sx={{ flexGrow: 1, mr: 16 }}>
                                  <ThemeImage src={content.image} />
                                </Box>
                              )}
                              <Column
                                sx={{
                                  mb: [16, null, null, 32],
                                  "& > * + div": {
                                    mt: 32
                                  }
                                }}
                              >
                                {content.elements}
                              </Column>
                            </Row>
                          </CollapsibleNew>
                        )
                      })}
                    </Box>
                  </Row>
                </>
              </Flex>
            </m.div>
          </Flex>
        </Box>
        {recommendationCollection && (
          <Row key={`product-recommendations-${product.sku}`}>
            <Box
              sx={{
                width: "100%",
                mx: StandardXAxisPadding,
                mt: [24, null, null, 36],
                mb: [24, null, null, 116]
              }}
            >
              <ProductRecommendations
                collections={recommendationCollection}
                variant="pdp"
              />
            </Box>
          </Row>
        )}
        <InTheField
          product={product}
          onImageClick={idx =>
            handleDisplayZoomGallery(product.galleryImages.length + idx)
          }
          onDataRefresh={data => (zoomVars.prismicData = data)}
          previewData={previewData}
          productPrismicData={productPrismicData}
        />
        <ProductArticles
          tags={suitableFor}
          productId={product.id}
          productPrismicData={productPrismicData}
          previewData={previewData}
        />
      </Flex>
      {showZoomGallery && (
        <ZoomGalleryModal
          show={showZoomGallery}
          currentPage={zoomVars.index || 0}
          onClose={() => setShowZoomGallery(false)}
          images={zoomVars.images}
        />
      )}
    </>
  )
}

export const Product: React.FC<ProductProps> = ({ ...props }) => {
  return <ProductCore {...props} />
}
