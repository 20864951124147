import {
  BreadCrumbItem,
  BreadCrumbs,
  Label2,
  StandardXAxisPadding
} from "@sixty-six-north/ui-system"
import { TAG_FILTER_CONFIG, useTagFilter } from "filters/TagFilterHooks"
import React from "react"
import { useTranslation } from "react-i18next"
import Link from "../components/Link"
import { useHomeLink } from "../components/Links"
import { useLocaleAwareLinks, usePreferredLanguage } from "../i18n/StoreHooks"
import { DomainCategory } from "./models/DomainCategory"

export const ProductBreadCrumbs: React.FC<{
  category: DomainCategory | null | undefined
}> = ({ category }) => {
  const language = usePreferredLanguage()
  const homeLink = useHomeLink()
  const builder = useLocaleAwareLinks()
  const tagFilter = useTagFilter()
  const { t } = useTranslation("category")

  if (!category) {
    return null
  }

  const items = [
    <BreadCrumbItem key="home-link">
      <Label2>
        <Link {...homeLink}>{t("home", { defaultValue: "Home" })}</Link>
      </Label2>
    </BreadCrumbItem>
  ]

  category.ancestors.forEach(cat => {
    items.push(
      <BreadCrumbItem key={cat.id}>
        <Label2>
          <Link {...builder.categoryLink(cat)}>{cat.name[language]}</Link>
        </Label2>
      </BreadCrumbItem>
    )
  })

  items.push(
    <BreadCrumbItem key={category.id} active={true}>
      <Label2>
        <Link {...builder.categoryLink(category)}>
          {category.name[language]}
        </Link>
      </Label2>
    </BreadCrumbItem>
  )

  if (tagFilter) {
    items.push(
      <BreadCrumbItem key={tagFilter} active={true}>
        <Label2>
          <Link {...builder.tagLink(tagFilter, category.slug[language])}>
            {TAG_FILTER_CONFIG[tagFilter]?.name[language] || tagFilter}
          </Link>
        </Label2>
      </BreadCrumbItem>
    )
  }
  return (
    <BreadCrumbs
      sx={{
        px: StandardXAxisPadding,
        nav: {
          zIndex: 1
        }
      }}
    >
      {items.map(Item => Item)}
    </BreadCrumbs>
  )
}
