import { Language } from "@sixty-six-north/i18n"

import { Option } from "funfix-core"
import _uniqBy from "lodash/uniqBy"
import { ProductUri } from "../components/Links"
import { filterUndefinedKeys } from "../utils/Filter"
import { DetailVariantProxy } from "./DetailVariantProxy"
import { DomainCategory } from "./models/DomainCategory"
import { DomainProduct } from "./models/DomainProduct"
import {
  Colorway,
  CoreProductInformation
} from "./models/DetailedProductInformation"
import { Price } from "./models/Price"
import { VariantProxy } from "./VariantProxy"
import { SKU } from "./VariantProxyI"

export class ProductProxy {
  public product: DomainProduct
  public attributes: VariantProxy

  constructor(product: DomainProduct) {
    this.product = product
    this.attributes = new VariantProxy(product.masterVariant)
  }

  public get uri(): ProductUri {
    return new ProductUri(this.product.slug, this.product.key)
  }

  public toProductProjection(): DomainProduct {
    return this.product as DomainProduct
  }

  public categories(): DomainCategory[] {
    return this.product.categories.filter(it => it !== undefined) || []
  }
  public allVariants(): VariantProxy[] {
    return [this.product.masterVariant, ...this.product.variants].map(
      it => new VariantProxy(it)
    )
  }

  public key(): string | undefined {
    return this.product.key
  }

  public name(language: Language): Option<string> {
    return Option.of(this.product.name[language])
  }

  public code(): Option<string> {
    return Option.of(this.toProductProjection().key)
  }

  public slug(language: Language): Option<string> {
    return Option.of(this.product.slug[language])
  }
  public description(language: Language): Option<string> {
    if (!this.product.description) return Option.of("")
    return Option.of(this.product.description[language])
  }

  public listingDescription(language: Language): Option<string> {
    return new DetailVariantProxy(
      this.product.masterVariant
    ).listingDescription(language)
  }
  public fitDescription(language: Language): Option<string> {
    return new DetailVariantProxy(this.product.masterVariant).fitDescription(
      language
    )
  }

  public colorName(language: Language): Option<string> {
    return new DetailVariantProxy(this.product.masterVariant).colorName(
      language
    )
  }

  public slugExistsFor(language: Language): boolean {
    return this.slug(language).nonEmpty()
  }

  public seoDescription(language: Language): Option<string> {
    return Option.of(this.product.seoDescription).flatMap(it =>
      Option.of(it[language])
    )
  }

  private _colorways(language: Language): Record<string, Colorway> {
    const colorways: Record<string, Colorway> = {}

    _uniqBy(this.allVariants(), it => it.variant.colorCode).forEach(v => {
      const color = v.colorCode().get()
      colorways[color] =
        colorways[color] ||
        (filterUndefinedKeys({
          assets: v.assets(),
          prices: v.variant.prices,
          color,
          term: v.colorTerm().get().key,
          name: v.colorName(language).getOrElse(""),
          hexcode: v.colorHex().getOrElse(""),
          colorwayAvailability: v.variant.variantAvailability,
          variantDiscount: undefined,
          modelDetail: v.modelDetails(),
          salesChannels: [],
          washingInstructions: [],
          variants: [],
          index: Object.keys(colorways).length
        }) as unknown as Colorway)
    })

    return colorways
  }
  public hierarchicalProduct(language: Language): CoreProductInformation {
    return {
      key: this.key() || "",
      id: this.product.id,
      version: -1,
      name: this.name(language).getOrElse(""),
      slug: this.product.slug,
      categories: this.categories(),
      description: this.description(language).getOrElse(""),
      listingDescription: this.listingDescription(language).getOrElse(""),
      seoDescription: this.seoDescription(language).getOrElse(""),
      colorways: this._colorways(language),
      masterSku: this.product.masterVariant.sku as SKU,
      facets: {
        functionality: [],
        sizes: [],
        style: ""
      },
      attributes: {
        bestFor: {},
        garmentComposition: [],
        washingInstructions: [],
        recommendations: []
      },
      prices: this.product.masterVariant.prices as Price[]
    }
  }
}
