import { ResizeObserver } from "@juggle/resize-observer"
import { ArrowLeft, ArrowRight } from "@sixty-six-north/ui-system"
import { motion, useAnimationControls, AnimatePresence } from "framer-motion"
import { throttle } from "lodash"
import React, { useEffect, useState, useMemo, Ref } from "react"
import useMeasure from "react-use-measure"
import { Box, BoxProps } from "theme-ui"
import { CarouselPaginationType } from "../../prismic/PrismicModels"

interface PaginationButtonConfiguration {
  justifyContent: "flex-start" | "flex-end"
  gradient: "90" | "270"
  paginationIncrement: number
}
const PaginationButtonLeft: PaginationButtonConfiguration = {
  justifyContent: "flex-start",
  gradient: "90",
  paginationIncrement: -1
}
const PaginationButtonRight: PaginationButtonConfiguration = {
  justifyContent: "flex-end",
  gradient: "270",
  paginationIncrement: 1
}
const PaginationButton: React.FC<{
  height?: string
  width?: string
  direction: "left" | "right"
  paginate: (direction: number) => void
}> = ({ width = "10%", height = "100%", paginate, direction }) => {
  const { justifyContent, gradient, paginationIncrement } =
    direction === "left" ? PaginationButtonLeft : PaginationButtonRight
  const Arrow = direction === "left" ? ArrowLeft : ArrowRight
  return (
    <button
      type="button"
      aria-label={`paginate ${direction}`}
      sx={{
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent,
        width,
        height,
        zIndex: 15,
        px: 8,
        py: 0,
        [direction]: 0,
        top: 0,
        border: "none",
        color: "white",
        backgroundColor: "rgb(0,0,0)",
        background: `linear-gradient(${gradient}deg, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0) 100%)`,
        ":hover": {
          background: `linear-gradient(${gradient}deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,.1) 100%)`
        }
      }}
      onClick={() => paginate(paginationIncrement)}
    >
      <Arrow width="50px" height="50px" />
    </button>
  )
}

const swipeConfidenceThreshold = 800
interface IndexedNode {
  index: number
  child: React.ReactNode
}
export interface CarouselProps {
  dragEnabled?: boolean
  keyEventsEnabled?: boolean
  paginationType?: CarouselPaginationType
  autoPaginateIntervalInSeconds?: number
  animationDurationInSeconds?: number
}

interface PaginationState {
  next: boolean
  previous: boolean
}

const FiniteCarousel: React.FC<
  {
    carouselNodes: IndexedNode[]
    carouselDimensions: CarouselDimensions
    numberOfVisibleColumns?: number
    pagination: Pagination
  } & CarouselProps
> = ({
  carouselNodes,
  carouselDimensions,
  animationDurationInSeconds,
  numberOfVisibleColumns = carouselDimensions.numberOfVisibleColumns,
  pagination
}) => {
  const carouselChildren = carouselNodes
  const maxPage = carouselNodes.length - numberOfVisibleColumns

  useEffect(() => {
    const page = pagination.page
    pagination.setState({
      next: page !== maxPage,
      previous: page !== 0
    })
  }, [pagination.page, maxPage, numberOfVisibleColumns])

  return (
    <Box
      as={"ul"}
      sx={{
        overflow: "none",
        width: carouselDimensions.carouselWidth,
        marginLeft: 0,
        pointerEvents: "all"
      }}
    >
      {carouselChildren.map((child, index) => (
        <motion.li
          key={`${index}-${child.index}`}
          custom={index}
          animate={{
            x: -1 * pagination.page * carouselDimensions.columnWidth
          }}
          transition={{
            duration: animationDurationInSeconds,
            ease: "easeInOut"
          }}
          sx={{
            display: "inline-block",
            width: carouselDimensions.columnWidth
          }}
        >
          {child.child}
        </motion.li>
      ))}
    </Box>
  )
}

const swap = <T,>(array: T[], index: number): T[] => {
  const left = array.slice(0, index)
  const right = array.slice(index)
  return [...right, ...left]
}

const InfiniteCarousel: React.FC<
  {
    carouselNodes: IndexedNode[]
    carouselDimensions: CarouselDimensions
    autoPaginateIntervalInSeconds?: number
    animationDurationInSeconds?: number
    pagination: Pagination
  } & CarouselProps
> = ({
  carouselNodes,
  carouselDimensions,
  autoPaginateIntervalInSeconds = 0,
  animationDurationInSeconds = 1 / 2,
  pagination
}) => {
  const [carouselChildren, setCarouselChildren] = useState(
    swap(carouselNodes, pagination.page)
  )
  useEffect(() => {
    if (autoPaginateIntervalInSeconds <= 0) return
    setInterval(
      () => pagination.paginate(1),
      Math.max(
        animationDurationInSeconds + 0.1,
        autoPaginateIntervalInSeconds
      ) * 1000
    )
  }, [])

  useEffect(() => {
    const page = pagination.page
    const totalNumberOfPages = carouselNodes.length
    const result = page % totalNumberOfPages
    const newPage = result < 0 ? totalNumberOfPages + result : result
    setCarouselChildren(swap(carouselNodes, newPage))
  }, [pagination.page])

  return (
    <Box
      as={"ul"}
      sx={{
        overflow: "none",
        width: carouselDimensions.carouselWidth,
        pointerEvents: "all"
      }}
    >
      {carouselChildren.map(({ child, index: childIndex }, index) => (
        <AnimatePresence key={index} initial={false} mode={"popLayout"}>
          <motion.li
            key={`${index}-${childIndex}`}
            custom={index}
            initial={{
              x: carouselDimensions.columnWidth * pagination.direction
            }}
            animate={{
              x: 0
            }}
            transition={{
              duration: animationDurationInSeconds,
              ease: "easeInOut"
            }}
            exit={{
              x: -1 * pagination.direction * carouselDimensions.columnWidth
            }}
            sx={{
              display: "inline-block",
              width: carouselDimensions.columnWidth,
              backgroundColor: "white"
            }}
          >
            {child}
          </motion.li>
        </AnimatePresence>
      ))}
    </Box>
  )
}

interface Pagination {
  paginate: (increment: number) => void
  setState: (state: PaginationState) => void
  page: number
  canPageNext: boolean
  canPagePrevious: boolean
  direction: number
}
const usePagination = (): Pagination => {
  const [direction, setDirection] = useState(0)
  const [page, setPage] = useState(0)
  const [paginationState, setPaginationState] = useState<PaginationState>({
    next: true,
    previous: true
  })
  return {
    paginate: (increment: number) => {
      setDirection(increment > 0 ? 1 : -1)
      setPage(prev => prev + increment)
    },
    setState: setPaginationState,
    get page() {
      return page
    },
    get canPageNext() {
      return paginationState.next
    },
    get canPagePrevious() {
      return paginationState.previous
    },
    get direction() {
      return direction
    }
  }
}

interface CarouselDimensions {
  ref: Ref<unknown>
  carouselWidth: string
  columnWidth: number
  buttonWidth: number
  numberOfVisibleColumns: number
}

const useCarouselDimensions = (numberOfColumns: number): CarouselDimensions => {
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver })

  const [numberOfVisibleColumns, setNumberOfVisibleColumns] =
    useState<number>(4)
  const [columnWidth, setColumnWidth] = useState<number>(
    (100 / numberOfColumns) * bounds.width
  )
  const [buttonWidthPercentage, setButtonWidthPercentage] = useState(10)
  const [carouselWidth, setCarouselWidth] = useState(
    `calc(23% * ${numberOfColumns})`
  )

  useEffect(() => {
    setButtonWidthPercentage(bounds.width > 600 ? 10 : 20)
    setNumberOfVisibleColumns(
      bounds.width > 900 ? 4 : bounds.width <= 900 && bounds.width > 600 ? 3 : 2
    )
  }, [bounds.width])

  useEffect(() => {
    const itemPercentageWidth =
      numberOfColumns > numberOfVisibleColumns
        ? (100 - buttonWidthPercentage) / numberOfVisibleColumns
        : 100 / numberOfVisibleColumns

    setCarouselWidth(`calc(${itemPercentageWidth}% * ${numberOfColumns + 1})`)

    const itemWidth =
      (bounds.width - 2 ** numberOfVisibleColumns) * (itemPercentageWidth / 100)
    setColumnWidth(itemWidth)
  }, [numberOfVisibleColumns, numberOfColumns, bounds.width])

  return {
    get buttonWidth(): number {
      return buttonWidthPercentage
    },
    get carouselWidth(): string {
      return carouselWidth
    },
    get columnWidth(): number {
      return columnWidth
    },
    get numberOfVisibleColumns(): number {
      return numberOfVisibleColumns
    },
    ref
  }
}
export const ContinuousCarousel: React.FC<
  BoxProps &
    CarouselProps & {
      carouselName?: string
      children: React.ReactNode[]
    }
> = ({
  children,
  dragEnabled = true,
  keyEventsEnabled = true,
  paginationType = "finite",
  autoPaginateIntervalInSeconds = 0,
  animationDurationInSeconds = 1 / 2,
  carouselName = "carousel"
}) => {
  const animationDurationMilliseconds = animationDurationInSeconds * 1000

  const carouselDimensions = useCarouselDimensions(
    React.Children.count(children)
  )
  const pagination = usePagination()

  const carouselElements: IndexedNode[] = React.Children.toArray(children).map(
    (child, index) => ({
      index,
      child
    })
  )
  const doPaginate = (increment: number) => {
    pagination.paginate(increment)
  }
  const paginate = useMemo(
    () =>
      throttle(doPaginate, animationDurationMilliseconds, {
        leading: true,
        trailing: false
      }),
    []
  )
  // const paginate = doPaginate

  useEffect(() => {
    const handleArrows = (event: KeyboardEvent): void => {
      // accessibility: stop keyboard interactions with Gallery when user is moving within a radiogroup
      if ((event.target as HTMLInputElement)?.type === "radio") return

      if (event.code === "ArrowRight") {
        paginate(1)
      }
      if (event.code === "ArrowLeft") {
        paginate(-1)
      }
    }

    if (keyEventsEnabled) {
      window.addEventListener("keydown", handleArrows)
    }
    return () => {
      window.removeEventListener("keydown", handleArrows)
    }
  }, [keyEventsEnabled])

  return (
    <Box
      ref={carouselDimensions.ref}
      sx={{
        display: "block",
        overflow: "hidden",
        position: "relative"
      }}
    >
      {pagination.canPagePrevious && (
        <PaginationButton
          direction={"left"}
          width={`${carouselDimensions.buttonWidth}%`}
          paginate={paginate}
        />
      )}
      {pagination.canPageNext && (
        <PaginationButton
          direction={"right"}
          width={`${carouselDimensions.buttonWidth}%`}
          paginate={paginate}
        />
      )}
      <AnimatePresence mode={"wait"}>
        <motion.div
          key={carouselName}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.25 }}
          exit={{ opacity: 0 }}
          sx={{
            touchAction: "none"
          }}
          dragElastic={1}
          onPanEnd={(_, info) => {
            if (!dragEnabled) return
            const swipe = info.velocity.x
            const swipeDirection = info.velocity.x < 0 ? -1 : 1
            const swipePower = swipe * swipeDirection

            const pagesToMove = Math.min(
              2,
              Math.ceil(swipePower / swipeConfidenceThreshold)
            )
            paginate(-1 * pagesToMove * swipeDirection)
          }}
        >
          {paginationType === "finite" ? (
            <FiniteCarousel
              carouselNodes={carouselElements}
              carouselDimensions={carouselDimensions}
              pagination={pagination}
              animationDurationInSeconds={animationDurationInSeconds}
            />
          ) : (
            <InfiniteCarousel
              carouselNodes={carouselElements}
              carouselDimensions={carouselDimensions}
              pagination={pagination}
              animationDurationInSeconds={animationDurationInSeconds}
              autoPaginateIntervalInSeconds={autoPaginateIntervalInSeconds}
            />
          )}
        </motion.div>
      </AnimatePresence>
    </Box>
  )
}
