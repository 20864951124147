import _intersection from "lodash/intersection"
import _isArray from "lodash/isArray"
import { ParsedUrlQuery, ParsedUrlQueryInput } from "querystring"
import { CoreProductInformation } from "../product/models/DetailedProductInformation"

export class AttributeFilter {
  public filterService: FilterService
  public filterKey: string
  // @ts-ignore
  private _values: string[] = []

  constructor(filterService: FilterService, key: string) {
    this.filterService = filterService
    this.filterKey = key
  }

  get values(): string[] {
    return this._values
  }

  set values(value: string[]) {
    this._values = value
  }

  public add(value: string): AttributeFilter {
    this.values.push(value)
    return this
  }

  public remove(value: string) {
    this.values = this.values.filter(it => it !== value)
    return this
  }

  public filter(value: string): boolean {
    return this.values.length === 0 || this.values.indexOf(value) > -1
  }

  public isActive(value: string): boolean {
    return this.values.indexOf(value) > -1
  }

  public toggle(value: string): AttributeFilter {
    if (this.isActive(value)) return this.remove(value)
    else return this.add(value)
  }

  public clear(): AttributeFilter {
    this.values = []
    return this
  }

  public fromQuery(query: ParsedUrlQuery): AttributeFilter {
    if (!query[this.filterKey]) {
      return this
    } else if (_isArray(query[this.filterKey])) {
      ;(query[this.filterKey] as string[]).forEach(it => this.add(it))
      return this
    } else {
      return this.add(query[this.filterKey] as string)
    }
  }

  public toQuery(): ParsedUrlQuery {
    const map: ParsedUrlQuery = {}
    if (this.values.length > 0) {
      map[this.filterKey] = this.values
    }
    return map
  }
}

export class FilterService {
  public colorTerm: AttributeFilter = new AttributeFilter(this, "colorTerm")
  public size: AttributeFilter = new AttributeFilter(this, "size")
  public functionality: AttributeFilter = new AttributeFilter(
    this,
    "functionality"
  )
  public listingLabel: AttributeFilter = new AttributeFilter(
    this,
    "listingLabel"
  )
  public style: AttributeFilter = new AttributeFilter(this, "style")

  public filter(products: CoreProductInformation[]): CoreProductInformation[] {
    const filteredProducts: CoreProductInformation[] = products.filter(
      product => {
        return this.filterWithVariants(product)
      }
    )
    return filteredProducts
  }

  public clear() {
    this.colorTerm.clear()
    this.size.clear()
    this.listingLabel.clear()
    this.style.clear()
    this.functionality.clear()
  }

  public fromQuery(query: ParsedUrlQuery): FilterService {
    this.colorTerm.fromQuery(query)
    this.size.fromQuery(query)
    this.listingLabel.fromQuery(query)
    this.style.fromQuery(query)
    this.functionality.fromQuery(query)
    return this
  }

  public toQuery(): ParsedUrlQueryInput {
    return {
      ...this.colorTerm.toQuery(),
      ...this.style.toQuery(),
      ...this.functionality.toQuery(),
      ...this.listingLabel.toQuery(),
      ...this.size.toQuery()
    }
  }

  private filterWithVariants(product: CoreProductInformation): boolean {
    const allSizesKeys = product.facets.sizes
    const allStylesKeys = product.facets.style
    const allFunctionality = product.facets.functionality
    const sizeResult =
      this.size.values.length > 0
        ? _intersection(allSizesKeys, this.size.values).length > 0
        : true
    const styleResult =
      this.style.values.length > 0
        ? _intersection([allStylesKeys], this.style.values).length > 0
        : true
    const functionalityResult =
      this.functionality.values.length > 0
        ? _intersection(allFunctionality, this.functionality.values).length > 0
        : true

    const colorResult =
      this.colorTerm.values.length === 0 ||
      Object.values(product.colorways)
        .map(c => c.term)
        .some(term => this.colorTerm.filter(term))

    // const res = !!variants.find(variant => {
    //   const proxy = new VariantProxy(variant)
    //   const color = proxy.colorTermKey()
    //   const colorResult =
    //     this.colorTerm.values.length === 0 ||
    //     color.map(it => this.colorTerm.filter(it)).getOrElse(false)
    // })

    return colorResult && styleResult && sizeResult && functionalityResult
  }
}
