//jsxhook

import { LineItem, Order } from "@commercetools/platform-sdk"
import { useCustomerState } from "customer/CustomerHooks"
import { useStoreContext } from "i18n/StoreHooks"
import { DetailsPageVariants } from "product/models/DetailsPageVariant"
import { createLogger } from "utils/createLogger"
import { CartCalculator } from "../cart/CartCalculator"
import { AppConfig } from "../Config"
import { UK } from "../i18n/Region"
import { productDal } from "../product/ProductDal"
import { ProductProxy } from "../product/ProductProxy"
import { VariantProxy } from "../product/VariantProxy"

const logger = createLogger("truefit")

const noOpTfcAPI = (...args) => {
  logger.info(`tfcapi is not defined when trying to: ${args}`)
}

export const useTrueFit = () => {
  const customer = useCustomerState()
  const store = useStoreContext()
  const {
    // @ts-ignore
    region: { language, country }
  } = store

  logger.info(`Truefit enabled stores: ${AppConfig.truefit.stores}`)
  const locale = `${language}_${country.toUpperCase()}`
  const available = AppConfig.truefit.stores.includes(
    store.country.toUpperCase()
  )

  const truefitApi =
    // @ts-ignore
    available && typeof tfcapi !== "undefined" ? tfcapi : noOpTfcAPI

  function productsFor(lineItems: LineItem[]) {
    const products = lineItems.map(item => {
      return productDal.projectionById(item.productId).then(product => {
        const variant = DetailsPageVariants.fromProductVariant(item.variant)
        const variantProxy = new VariantProxy(variant)
        const price = CartCalculator.lineItemTotalPrice(item)
        return {
          productId: product.key || "",
          price: price.centAmount / 100,
          currency: price.currencyCode,
          size: variantProxy.sizeName(UK.language).getOrElse(""),
          sku: variantProxy.sku(),
          quantity: item.quantity,
          colorId: variantProxy.colorName(UK.language).getOrElse("")
        }
      })
    })

    return Promise.all(products)
  }

  const reportPurchase = (order: Order) =>
    productsFor(order.lineItems)
      .then(products => {
        return {
          userId: customer?.id || "",
          orderId: order.id,
          locale,
          products
        }
      })
      .then(event => {
        logger.log("truefitPurchase", event)
        truefitApi("track", "checkout", event)
        return true
      })

  return {
    available: available,
    calculate: () => truefitApi("calculate"),
    reportPurchase
  }
}
